/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ## A custom template from the reclaim-api/api-client         ##
 * ## repo was used.                                            ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";

export enum DomainSetting {
  INVITE_ONLY = "INVITE_ONLY",
  REQUEST = "REQUEST",
  OPEN = "OPEN",
  AUTO_DOMAIN_CAPTURE = "AUTO_DOMAIN_CAPTURE",
}

export interface InvitationResponse {
  accept: boolean;
}

export enum InvitationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DELETED = "DELETED",
}

export interface JoinableTeam {
  teamId: number;
  name: string;
  paidProSeats: number;
  paidTeamSeats: number;
  memberCount: number;
  avatarUrls: string[];
  domainSetting: DomainSetting;
}

export enum MembershipRole {
  USER = "USER",
  ADMIN = "ADMIN",
}

export type Object = object;

export interface PartialTeam {
  name?: string | null;
  billingEmail?: string | null;
  autoSubscribe?: boolean | null;
  userInviteLevel?: ReclaimEdition & any;
  domainSetting?: DomainSetting & any;
}

export interface PartialTeamInvitation {
  email: string;
  edition: ReclaimEdition;
  role: MembershipRole;
  inviteMessage: string;
}

export interface PartialTeamMember {
  edition: ReclaimEdition;
  role: MembershipRole;
}

export interface PriceItem {
  externalId: string;
  edition: ReclaimEdition;
  frequency: SubscriptionFrequency;
}

export interface PriceList {
  items: PriceItem[];
}

export enum ReclaimEdition {
  NONE = "NONE",
  TRIAL = "TRIAL",
  ASSISTANT = "ASSISTANT",
  PRO = "PRO",
  TEAM = "TEAM",
  REWARD = "REWARD",
  LEGACY_PRO_TRIAL = "LEGACY_PRO_TRIAL",
  LEGACY_TEAM_TRIAL = "LEGACY_TEAM_TRIAL",
}

export enum RequestedTeamStatus {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum SubscriptionFrequency {
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum SubscriptionStatus {
  TRIALING = "TRIALING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface Team {
  id: number;
  name: string;
  billingEmail: string;
  subscriptionFrequency: SubscriptionFrequency;
  paidProSeats: number;
  paidTeamSeats: number;
  autoSubscribe: boolean;
  userInviteLevel: ReclaimEdition;
  stripeCustomerId?: string | null;
  stripeSubscriptionId?: string | null;
  subscriptionStatus: SubscriptionStatus;
  updated: string;
  trialEnd: string;
  subscriptionChanged?: string | null;
  version: number;
  domainSetting: DomainSetting;
}

export interface TeamDomainView {
  teamId: number;
  domainName: string;
}

export interface TeamInvitation {
  id: string;
  teamId: number;
  email: string;
  edition: ReclaimEdition;
  role: MembershipRole;
  status: InvitationStatus;
  created: string;
  creatorUserId?: string | null;
  updated: string;
  version: number;
  personalizedInviteMessage?: string | null;
}

export interface TeamInvoice {
  dueDate: string;
  total: string;
  link: string;
}

export interface TeamJoinResponse {
  userId: string;
  accept: boolean;
  role?: MembershipRole & any;
  editionAfterTrial?: ReclaimEdition & any;
}

export interface TeamMember {
  id: TeamMemberId;
  edition: ReclaimEdition;
  editionAfterTrial: ReclaimEdition;
  role: MembershipRole;
  created: string;
  updated: string;
  avatarUrl?: string | null;
  email: string;
  domainName: string;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  trialEnd: string;
  previousTeamId?: number | null;
  requestedTeamId?: number | null;
  version: number;
  requestedTeamStatus?: RequestedTeamStatus & any;
}

export interface TeamMemberId {
  teamId: number;
  userId: string;
}

export interface TeamMemberView {
  teamMemberId: TeamMemberId;
  avatarUrl: string;
  membershipRole: MembershipRole;
  edition: ReclaimEdition;
  editionAfterTrial: ReclaimEdition;
  trialEnd: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  status: TeamMemberViewStatus;
  invitationId: string;
  inviteUrl: string;
  personalizedInviteMessage: string;
  daysLeftInTrial: number;
  requestedTeamId: number;
  requestedTeamStatus: RequestedTeamStatus;
}

export enum TeamMemberViewStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface TeamView {
  id: number;
  name: string;
  billingEmail: string;
  autoSubscribe: boolean;
  userInviteLevel: ReclaimEdition;
  domainSetting: DomainSetting;
  paidProSeats: number;
  paidTeamSeats: number;
  proSeatsUsed: number;
  teamSeatsUsed: number;
  totalSeatsUsed: number;
  totalSeats: number;
  members: TeamMemberView[];
  trialEnd: string;
  daysLeftInTrial: number;
  subscriptionStatus: SubscriptionStatus;
}

export type LocalTime = string;

export namespace team {
  /**
   * @tags team
   * @name newSession
   * @request GET:/team/create/subscription/session
   */
  export namespace NewSession {
    export type RequestQuery = {
      frequency: SubscriptionFrequency;
      seats: number;
      edition: ReclaimEdition;
      successUrl: string;
      cancelUrl: string;
    };
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getCurrentTeam
   * @request GET:/team/current
   */
  export namespace GetCurrentTeam {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamView;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name patchTeam
   * @request PATCH:/team/current
   */
  export namespace PatchTeam {
    export type RequestQuery = {};
    export type RequestBody = PartialTeam;
    export type ResponseBody = TeamView;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getCurrentTeamDomains
   * @request GET:/team/current/domains
   */
  export namespace GetCurrentTeamDomains {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamDomainView[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name deleteInvitation
   * @request DELETE:/team/current/invitation/{id}
   */
  export namespace DeleteInvitation {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getInvitations
   * @request GET:/team/current/invitations
   */
  export namespace GetInvitations {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamInvitation[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name createInvitation
   * @request POST:/team/current/invitations
   */
  export namespace CreateInvitation {
    export type RequestQuery = {};
    export type RequestBody = PartialTeamInvitation[];
    export type ResponseBody = TeamInvitation[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name updateInvitation
   * @request PATCH:/team/current/invitations/{inviteId}
   */
  export namespace UpdateInvitation {
    export type RequestQuery = {};
    export type RequestBody = PartialTeamMember;
    export type ResponseBody = TeamInvitation;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getInviteableTeamMembers
   * @summary Returns team members that are not members of the user's team, overlap with domain(s) in the user's team, and do not
 belong to paying teams.
   * @request GET:/team/current/inviteable
   * @description Returns team members that are not members of the user's team, overlap with domain(s) in the user's team, and do not belong to paying teams.
   */
  export namespace GetInviteableTeamMembers {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamMember[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name joinResponses
   * @request POST:/team/current/joinResponses
   */
  export namespace JoinResponses {
    export type RequestQuery = {};
    export type RequestBody = TeamJoinResponse[];
    export type ResponseBody = TeamMember[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name leaveTeam
   * @request POST:/team/current/leave
   */
  export namespace LeaveTeam {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Team;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getMembers
   * @request GET:/team/current/members
   */
  export namespace GetMembers {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamMemberView[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name deleteMember
   * @request DELETE:/team/current/members
   */
  export namespace DeleteMember {
    export type RequestQuery = { userId: string };
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name patchMember
   * @request PATCH:/team/current/members
   */
  export namespace PatchMember {
    export type RequestQuery = { userId: string };
    export type RequestBody = PartialTeamMember;
    export type ResponseBody = TeamMemberView;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getMembership
   * @request GET:/team/current/membership
   */
  export namespace GetMembership {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamMemberView;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getPriceList
   * @request GET:/team/current/price-list
   */
  export namespace GetPriceList {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = PriceList;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getRequests
   * @summary Returns team members that have requested to be part of the user's team (and currently are not).
   * @request GET:/team/current/requests
   * @description Returns team members that have requested to be part of the user's team (and currently are not).
   */
  export namespace GetRequests {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamMember[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name manageSession
   * @request GET:/team/current/subscription/session
   */
  export namespace ManageSession {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name listInvoices
   * @request GET:/team/invoices
   */
  export namespace ListInvoices {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = TeamInvoice[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name getJoinableTeams
   * @request GET:/team/joinable
   */
  export namespace GetJoinableTeams {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = JoinableTeam[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name acceptInvitation
   * @request POST:/team/{teamId}/invitations/{invitationId}
   */
  export namespace AcceptInvitation {
    export type RequestQuery = {};
    export type RequestBody = InvitationResponse;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name requestToJoinTeam
   * @summary A request from a user to join a team.
   * @request POST:/team/{teamId}/request
   * @description A request from a user to join a team.
   */
  export namespace RequestToJoinTeam {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = any;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags team
   * @name cancelRequestToJoinTeam
   * @request POST:/team/{teamId}/request/cancel
   */
  export namespace CancelRequestToJoinTeam {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = any;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}
export namespace webhook {
  /**
   * @name post
   * @request POST:/webhook/stripe
   */
  export namespace Post {
    export type RequestQuery = {};
    export type RequestBody = LocalTime;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}

export const HEADER_BUILD_ID = "X-Reclaim-Build-Id";

export type RequestParams = Omit<AxiosRequestConfig, "url" | "data" | "method"> & {
  secure?: boolean;
};

// TODO: The need for this is hard codded into the codegen source... so dumb
enum BodyType {
  Json,
}

export type RequestQueryParamsType = Record<string | number, any>;

export type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
export type TypedErrorPromise<ResolveType, RejectType = unknown> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): Promise<ResolveType | TResult>;
  finally<TResult = never>(onfinally?: (() => void) | undefined | null): Promise<ResolveType | TResult>;
  readonly [Symbol.toStringTag]: string;
};

export class ReclaimApiError<
  E extends { status: number; response?: unknown | null } = { status: number; response?: unknown | null },
> extends Error {
  status: E["status"];
  statusText?: string;
  headers: unknown;
  body: E["response"];
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.status = res?.status;
    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

export class NetworkError extends Error {
  status: "NetworkError" = "NetworkError";
  statusText: any;
  headers: any;
  body: any;
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

// export type ClientConfig = ApiConfig<SecurityDataType,> & {BUILD_ID?: string}
export type ClientConfig = ApiConfig<any> & { BUILD_ID?: string };

class HttpClient<SecurityDataType> {
  public baseUrl: string = "https://api.app.reclaim.ai/api";
  public client: AxiosInstance;
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;
  private baseApiParams: RequestParams = {
    headers: {
      "Content-Type": "application/json",
      Referrer: "no-referrer",
    },
  };

  private requestHooks = {};
  private responseHooks = {};

  constructor({ baseUrl, baseApiParams, securityWorker, BUILD_ID }: ClientConfig = { BUILD_ID: "unknown" }) {
    if (!!baseUrl) this.baseUrl = baseUrl;
    if (!!baseApiParams) this.baseApiParams = baseApiParams;
    if (!!securityWorker) this.securityWorker = securityWorker;
    this.client = axios.create({
      baseURL: baseUrl || this.baseUrl,
      withCredentials: true,
      headers: {
        [HEADER_BUILD_ID]: BUILD_ID,
        ...(this.baseApiParams.headers || {}),
        post: {
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    });
    this.client.interceptors.request.use((config) => {
      Object.keys(this.requestHooks).forEach((name) => {
        this.requestHooks[name](config);
      });

      return config;
    });

    this.client.interceptors.response.use(
      (r) => {
        Object.keys(this.responseHooks).forEach((name) => {
          if (r.status) {
            return this.responseHooks[`${name} - Status: ${r.status}`](new ReclaimApiError(r));
          }

          this.responseHooks[`${name} - Status: network`](new NetworkError(r));
        });
        return r;
      },
      (error) => {
        Object.keys(this.responseHooks).forEach((name) => {
          this.responseHooks[name](error);
        });
        return error;
      },
    );
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  public registerRequestHook(name: string, fn: (config: AxiosRequestConfig) => void | Promise<void>) {
    this.requestHooks[name] = fn;
  }

  public registerResponseHook(
    name: string,
    status: 401 | 500 | "NetworkError",
    fn: (error: any) => void | Promise<void>,
  ) {
    this.responseHooks[`${name} - Status: ${status}`] = fn;
  }

  public request = <T extends unknown, E extends { status: number; response?: unknown | null }>(
    path: string,
    method: AxiosRequestConfig["method"],
    { secure, ...params }: RequestParams = {},
    data?: any,
    _dumbUnusedBodyType?: any,
    secureByDefault?: boolean,
  ): TypedErrorPromise<T, ReclaimApiError<E> | NetworkError> =>
    this.client
      .request({
        url: path,
        ...params,
        method,
        data,
        validateStatus: function (status) {
          return true;
        },
      })
      .then((r) => {
        if (r.status >= 200 && r.status < 300) {
          return !!r.data ? r.data : null;
        } else if (!r.status) {
          throw new NetworkError(r);
        } else {
          throw new ReclaimApiError(r);
        }
      });
}

/**
 * @title Reclaim Billing API
 * @version 0.1
 * @baseUrl https://api.app.reclaim.ai/api
 * Reclaim's awesome API
 */
export class ReclaimApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  team = {
    /**
     * @tags team
     * @name newSession
     * @request GET:/team/create/subscription/session
     */
    newSession: <ErrorTypes extends { status: number; response: any }>(
      query: {
        frequency: SubscriptionFrequency;
        seats: number;
        edition: ReclaimEdition;
        successUrl: string;
        cancelUrl: string;
      },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/create/subscription/session${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name getCurrentTeam
     * @request GET:/team/current
     */
    getCurrentTeam: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<TeamView, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name patchTeam
     * @request PATCH:/team/current
     */
    patchTeam: <ErrorTypes extends { status: number; response: any }>(data: PartialTeam, params?: RequestParams) =>
      this.request<TeamView, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current`,
        "PATCH",
        params,
        data,
      ),

    /**
     * @tags team
     * @name getCurrentTeamDomains
     * @request GET:/team/current/domains
     */
    getCurrentTeamDomains: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        TeamDomainView[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/domains`, "GET", params),

    /**
     * @tags team
     * @name deleteInvitation
     * @request DELETE:/team/current/invitation/{id}
     */
    deleteInvitation: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/invitation/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags team
     * @name getInvitations
     * @request GET:/team/current/invitations
     */
    getInvitations: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        TeamInvitation[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/invitations`, "GET", params),

    /**
     * @tags team
     * @name createInvitation
     * @request POST:/team/current/invitations
     */
    createInvitation: <ErrorTypes extends { status: number; response: any }>(
      data: PartialTeamInvitation[],
      params?: RequestParams,
    ) =>
      this.request<
        TeamInvitation[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/invitations`, "POST", params, data),

    /**
     * @tags team
     * @name updateInvitation
     * @request PATCH:/team/current/invitations/{inviteId}
     */
    updateInvitation: <ErrorTypes extends { status: number; response: any }>(
      inviteId: string,
      data: PartialTeamMember,
      params?: RequestParams,
    ) =>
      this.request<
        TeamInvitation,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/invitations/${inviteId}`, "PATCH", params, data),

    /**
     * @tags team
     * @name getInviteableTeamMembers
     * @summary Returns team members that are not members of the user's team, overlap with domain(s) in the user's team, and do not
 belong to paying teams.
     * @request GET:/team/current/inviteable
     * @description Returns team members that are not members of the user's team, overlap with domain(s) in the user's team, and do not belong to paying teams.
     */
    getInviteableTeamMembers: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<TeamMember[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/inviteable`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name joinResponses
     * @request POST:/team/current/joinResponses
     */
    joinResponses: <ErrorTypes extends { status: number; response: any }>(
      data: TeamJoinResponse[],
      params?: RequestParams,
    ) =>
      this.request<TeamMember[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/joinResponses`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags team
     * @name leaveTeam
     * @request POST:/team/current/leave
     */
    leaveTeam: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Team, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/leave`,
        "POST",
        params,
      ),

    /**
     * @tags team
     * @name getMembers
     * @request GET:/team/current/members
     */
    getMembers: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        TeamMemberView[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/members`, "GET", params),

    /**
     * @tags team
     * @name deleteMember
     * @request DELETE:/team/current/members
     */
    deleteMember: <ErrorTypes extends { status: number; response: any }>(
      query: { userId: string },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/members${this.addQueryParams(query)}`,
        "DELETE",
        params,
      ),

    /**
     * @tags team
     * @name patchMember
     * @request PATCH:/team/current/members
     */
    patchMember: <ErrorTypes extends { status: number; response: any }>(
      query: { userId: string },
      data: PartialTeamMember,
      params?: RequestParams,
    ) =>
      this.request<
        TeamMemberView,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/members${this.addQueryParams(query)}`, "PATCH", params, data),

    /**
     * @tags team
     * @name getMembership
     * @request GET:/team/current/membership
     */
    getMembership: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        TeamMemberView,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/current/membership`, "GET", params),

    /**
     * @tags team
     * @name getPriceList
     * @request GET:/team/current/price-list
     */
    getPriceList: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<PriceList, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/price-list`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name getRequests
     * @summary Returns team members that have requested to be part of the user's team (and currently are not).
     * @request GET:/team/current/requests
     * @description Returns team members that have requested to be part of the user's team (and currently are not).
     */
    getRequests: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<TeamMember[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/requests`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name manageSession
     * @request GET:/team/current/subscription/session
     */
    manageSession: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/current/subscription/session`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name listInvoices
     * @request GET:/team/invoices
     */
    listInvoices: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<TeamInvoice[], { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/invoices`,
        "GET",
        params,
      ),

    /**
     * @tags team
     * @name getJoinableTeams
     * @request GET:/team/joinable
     */
    getJoinableTeams: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        JoinableTeam[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/team/joinable`, "GET", params),

    /**
     * @tags team
     * @name acceptInvitation
     * @request POST:/team/{teamId}/invitations/{invitationId}
     */
    acceptInvitation: <ErrorTypes extends { status: number; response: any }>(
      teamId: number,
      invitationId: string,
      data: InvitationResponse,
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/${teamId}/invitations/${invitationId}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags team
     * @name requestToJoinTeam
     * @summary A request from a user to join a team.
     * @request POST:/team/{teamId}/request
     * @description A request from a user to join a team.
     */
    requestToJoinTeam: <ErrorTypes extends { status: number; response: any }>(teamId: number, params?: RequestParams) =>
      this.request<any, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/${teamId}/request`,
        "POST",
        params,
      ),

    /**
     * @tags team
     * @name cancelRequestToJoinTeam
     * @request POST:/team/{teamId}/request/cancel
     */
    cancelRequestToJoinTeam: <ErrorTypes extends { status: number; response: any }>(
      teamId: number,
      params?: RequestParams,
    ) =>
      this.request<any, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/team/${teamId}/request/cancel`,
        "POST",
        params,
      ),
  };
  webhook = {
    /**
     * @name post
     * @request POST:/webhook/stripe
     */
    post: <ErrorTypes extends { status: number; response: any }>(data: LocalTime, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/webhook/stripe`,
        "POST",
        params,
        data,
      ),
  };
}
